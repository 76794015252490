import React from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import HomeLayout from '../components/layouts/HomeLayout'
import Seo from '../components/seo'
import PropTypes from 'prop-types'
import ArticleBanner from '../components/common/ArticleBanner/ArticleBanner'
import ContactFormHubspot from '../components/forms/ContactForm/ContactFormHubspot'

const PageContactsTemplate = () => {
  const intl = useIntl()
  const location = typeof window !== 'undefined' ? window.location : { pathname: '', search: '' }
  const image = {
    file: {
      url: 'https://images.unsplash.com/photo-1584021978203-54c4a7cd73c3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80',
    },
  }

  return (
    <HomeLayout location={location}>
      <Seo
        title={intl.formatMessage({ id: 'pages.contacts.title' })}
        description={intl.formatMessage({ id: 'pages.contacts.description' })}
      />
      <ArticleBanner title={'Contacts'} location={location} image={image} />
      <div className="container">
        <div className="small-content">
          <div className="intro">{intl.formatMessage({ id: 'pages.contacts.introduction' })}</div>
          <ContactFormHubspot />
        </div>
      </div>
    </HomeLayout>
  )
}

PageContactsTemplate.propTypes = {
  pageContext: PropTypes.object,
}

export default PageContactsTemplate

export const pageQuery = graphql`
  query PageContactsQuery($locale: String!, $pageType: String) {
    allContentfulPage(filter: { node_locale: { eq: $locale }, pageType: { eq: $pageType } }) {
      edges {
        node {
          __typename
          id
          slug
          node_locale
          pageType
          title
          image {
            title
            file {
              url
            }
          }
          updatedAt
        }
      }
    }
  }
`
