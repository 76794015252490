import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './article-banner.module.scss'
import classNames from 'classnames'
import { Link, useIntl } from 'gatsby-plugin-intl'
import TwitterIcon from '../icons/twitter'
import LinkedinIcon from '../icons/linkedin'
import FacebookIcon from '../icons/facebook'

export default function ArticleBanner(props) {
  const intl = useIntl()

  return (
    <div
      className={classNames('with-overlay', styles.articleBanner)}
      style={{ backgroundImage: `url(${props.image.file?.url})` }}
    >
      <div className="overlay" />
      <div className="container content">
        <nav className="mb-4" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">{intl.formatMessage({ id: 'article-banner.home' })}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              {props.title}
            </li>
          </ol>
        </nav>
        <h1 className="mb-4 mb-md-5">{props.title}</h1>
        {props.subtitle && <h2 className="text-primary">{props.subtitle}</h2>}
        <div>
          <span className="mr-5">{intl.formatMessage({ id: 'article-banner.share' })}</span>
          <span>
            <a
              className={classNames(styles.iconLink, styles.twitter, 'ml-3 ml-md-5')}
              href={`https://twitter.com/intent/tweet?url=${props.location?.href}`}
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
            <a
              className={classNames(styles.iconLink, styles.linkedin)}
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${props.location?.href}`}
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinIcon />
            </a>
            <a
              className={classNames(styles.iconLink, styles.facebook)}
              href={`https://www.facebook.com/share.php?u=${props.location?.href}`}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

ArticleBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    title: PropTypes.string,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
