import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import HubspotForm from 'react-hubspot-form'

export default function ContactFormHubspot() {
  const intl = useIntl()
  const portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID || '25431082'
  const formId =
    intl.locale === 'en'
      ? process.env.GATSBY_HUBSPOT_CONTACT_FORM_ID_EN
      : process.env.GATSBY_HUBSPOT_CONTACT_FORM_ID_FR || 'f7e82a0a-769d-490e-aafb-868e86af8ddf'

  return (
    <HubspotForm
      portalId={portalId}
      formId={formId}
      region="eu1"
      onSubmit={() => console.log('Submit!')}
      onReady={form => console.log('Form ready!', form)}
      loading={
        <div className="p-5 d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status" />
        </div>
      }
    />
  )
}
